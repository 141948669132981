import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useCalendarContext } from "./CalendarContext";
import ColorHash from "color-hash";
import { navigate } from "gatsby";
import { useReservationSchedulesSubscription } from "../../__generated__/types";
import { MoneyOff, Paid, PriceCheck } from "@mui/icons-material";
import { MouseEventHandler, FC, memo } from "react";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";

const colorHash = new ColorHash();

type CalendarRangeProps = {
  top: number;
  left: number;
  height: number;
  width: number;
  isBefore: boolean;
  isAfter: boolean;
  client: {
    id: string;
    first_name: string;
    last_name: string;
  };
  status: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const CalendarRange: FC<CalendarRangeProps> = memo(
  ({ client, onClick, status, ...props }) => {
    const { top, left, height, width, isBefore, isAfter } = props;
    return (
      <Box
        onClick={onClick}
        sx={(theme) => ({
          gridArea: `${top}/${left}/span ${height}/span ${width}`,
          position: "absolute",
          height: "100%",
          width: "100%",
          padding: theme.spacing(0.8),
          paddingLeft: isBefore ? 0 : theme.spacing(0.8),
          paddingRight: isAfter ? 0 : theme.spacing(0.8),
        })}
      >
        <Box
          sx={(theme) => ({
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius / 4,
            backgroundColor: colorHash.hex(client.id),
            color: "white",
            height: "100%",
            ...(isBefore
              ? {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }
              : {}),
            ...(isAfter
              ? {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }
              : {}),
          })}
        >
          <Box display="flex" alignItems="center" sx={{ p: 1 }}>
            {status === "CONFIRMED" && <MoneyOff />}
            {status === "DEPOSIT" && <PriceCheck />}
            {status === "PAYED" && <Paid />}
            <Typography noWrap>
              {client.first_name} {client.last_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);

CalendarRange.displayName = "CalendarRange";

export const CalendarShedules = memo(() => {
  const { start, end } = useCalendarContext();
  const { selected } = useSelectedPropertyContext();
  const { data } = useReservationSchedulesSubscription({
    variables: {
      reservation_bool_exp: {
        arrival_date: { _lte: end },
        departure_date: { _gt: start },
        property_id: { _eq: selected },
      },
    },
  });
  return (
    <>
      {data?.reservation.map(
        ({
          id,
          client,
          arrival_date,
          departure_date,
          status,
          reservation_rooms,
        }) => {
          const isBefore = dayjs(arrival_date).isBefore(start);
          const isAfter = dayjs(departure_date).isAfter(end);
          const rangeStart = dayjs(isBefore ? start : arrival_date);
          const rangeEnd = dayjs(isAfter ? end : departure_date);
          const left = Number(rangeStart.format("DD")) + 1;
          const width = rangeEnd.diff(rangeStart, "days") + 1;
          return reservation_rooms
            .reduce((r, n) => {
              const lastSubArray = r[r.length - 1];
              if (
                !lastSubArray ||
                lastSubArray[lastSubArray.length - 1].room.room_number !==
                  n.room.room_number - 1
              ) {
                r.push([]);
              }
              r[r.length - 1].push(n);
              return r;
            }, [])
            .map(([{ room }, ...rooms]) => {
              const top = room.room_number + 1;
              const height = [{ room }, ...rooms].length;
              return (
                <CalendarRange
                  key={id + top}
                  top={top}
                  height={height}
                  left={left}
                  width={width}
                  client={client}
                  status={status}
                  isBefore={isBefore}
                  isAfter={isAfter}
                  onClick={() => {
                    navigate(`/reservations/${id}`);
                  }}
                />
              );
            });
        }
      )}
    </>
  );
});

CalendarShedules.displayName = "CalendarShedules";
