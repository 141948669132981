import { Box, Container } from "@mui/material";
import React from "react";
import CalendarShedule from "../components/schedule/CalendarSchedule";
import { CreateReservation } from "./reservations";

const SchedulePage = () => {
  return (
    <Container
      sx={{ mt: 2, display: "flex", flexDirection: "column" }}
      maxWidth={false}
    >
      <CreateReservation />
      <CalendarShedule />
    </Container>
  );
};

export default SchedulePage;
