import React from "react";
import { CalendarBase } from "./CalendarBase";
import { CalendarProvider } from "./CalendarContext";
import { CalendarShedules } from "./CalendarSchedules";
import { useRoomNumbersSubscription } from "../../__generated__/types";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";

export const CalendarShedule = () => {
  const { selected } = useSelectedPropertyContext();
  const { data } = useRoomNumbersSubscription({
    variables: { property_id: selected },
  });
  return (
    <CalendarProvider>
      {data && (
        <CalendarBase room={data.room}>
          <CalendarShedules />
        </CalendarBase>
      )}
    </CalendarProvider>
  );
};

export default CalendarShedule;
