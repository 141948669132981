import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import dayjs from "dayjs";
import { Button } from "gatsby-theme-material-ui";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ReservationForm, STATUSES_V2 } from "../components/ReservationForm";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import {
  useAllReservationsSubscription,
  useInsertReservationMutation,
  usePropertyStatsSubscription,
  useRoomDataSubscription,
} from "../__generated__/types";
import { DataGrid, bgBG } from "@mui/x-data-grid";
import { Close } from "@mui/icons-material";
import { translations } from "../translations";
import { TotalDisplay } from "../components/Invoice";
import { useFormikContext } from "formik";

const CreateReservationTotalDisplay = ({ conatainerSx }) => {
  const { values } = useFormikContext();
  const [discount, setDiscount] = useState(null);
  const { selected } = useSelectedPropertyContext();
  const { data, loading, error } = usePropertyStatsSubscription({
    variables: { id: selected },
  });

  const rooms = useRoomDataSubscription({
    variables: {
      where: {
        property_id: { _eq: selected },
        _or: values.rooms.map((id) => {
          return { id: { _eq: id } };
        }),
      },
      where1: {
        date: { _lte: values.departure_date, _gte: values.arrival_date },
      },
    },
  });
  const totalDays =
    dayjs(values.departure_date).diff(values.arrival_date, "day") + 1;

  if (loading || rooms.loading) return null;
  if (error || rooms.error) return null;

  return (
    <>
      <Typography variant="body2">Нощувки: {totalDays}</Typography>
      <TotalDisplay
        reservation={{
          arrival_date: values.arrival_date,
          departure_date: values.departure_date,
          pets: values.pets,
          reservation_rooms: rooms.data?.room.map((room) => ({ room })),
          discount: discount,
        }}
        property={{
          pet_price: data?.property_by_pk?.pet_price,
          down_payment: data?.property_by_pk?.down_payment,
        }}
        onDiscountChange={(props) => {
          setDiscount(props.discount);
        }}
        conatainerSx={conatainerSx}
      />
    </>
  );
};

export const CreateReservation = () => {
  const { selected } = useSelectedPropertyContext();
  const [open, setOpen] = React.useState(false);
  const [insertReservation, { error }] = useInsertReservationMutation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      const message =
        (error.message.includes("client_phone_number_key") &&
          translations[selected_locale].client_phone_number_key) ||
        (error.message.includes("client_email_key") &&
          translations[selected_locale].client_email_key) ||
        error.message;
      enqueueSnackbar(message, { variant: "error" });
    }
  }, [error]);
  const { selected_locale } = useSelectedPropertyContext();
  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        sx={{ mb: 1, alignSelf: "flex-end" }}
        onClick={() => setOpen(true)}
      >
        {translations[selected_locale].new_reservation}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations[selected_locale].new_reservation}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ReservationForm
            onSubmit={async (
              { arrival_date, departure_date, rooms, note, client, pets },
              actions
            ) => {
              await insertReservation({
                variables: {
                  objects: {
                    arrival_date,
                    departure_date,
                    note,
                    pets,
                    reservation_rooms: {
                      data: rooms.map((room_id) => ({ room_id })),
                    },
                    property_id: selected,
                    ...(client?.id
                      ? { client_id: client.id }
                      : {
                          client: {
                            data: {
                              first_name: client.first_name,
                              last_name: client.last_name,
                              phone_number: client.phone_number,
                              email: client.email,
                              property_id: selected,
                            },
                          },
                        }),
                  },
                },
              });
              actions.setSubmitting(false);
              actions.resetForm();
              setOpen(false);
              enqueueSnackbar(
                translations[selected_locale].reservation_success,
                {
                  variant: "success",
                }
              );
            }}
          >
            <CreateReservationTotalDisplay
              conatainerSx={{
                p: 0,
                mt: 0,
                justifyContent: "initial",
              }}
            />
          </ReservationForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

const IndexPage = () => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const {
    data,
    loading,
    error: queryError,
  } = useAllReservationsSubscription({
    variables: { property_id: selected },
  });
  return (
    <Container
      sx={{ mt: 2, display: "flex", flexDirection: "column" }}
      maxWidth="xl"
    >
      <CreateReservation />
      <Box>
        <div style={{ overflow: "hidden" }}>
          <DataGrid
            error={queryError?.message}
            loading={loading}
            autoHeight
            checkboxSelection={false}
            rows={
              data?.reservation?.map((r, index, arr) => ({
                id: r.id,
                number: arr.length - index,
                client_name: `${r.client.first_name} ${r.client.last_name}`,
                arrival_date: r.arrival_date,
                departure_date: r.departure_date,
                rooms: r.reservation_rooms,
                created_at: r.created_at,
                updated_at: r.updated_at,
                pets: r.pets,
                status: r.status,
              })) || []
            }
            columns={[
              {
                field: "number",
                headerName: translations[selected_locale].number_label,
                width: 50,
                filterable: false,
              },
              {
                field: "client_name",
                headerName: translations[selected_locale].client_name,
                minWidth: 150,
                flex: 1,
              },
              {
                field: "status",
                headerName: translations[selected_locale].status,
                width: 150,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={STATUSES_V2(selected_locale)[params.value].label}
                      variant="outlined"
                      color={STATUSES_V2(selected_locale)[params.value].color}
                    />
                  );
                },
              },
              {
                field: "arrival_date",
                headerName: translations[selected_locale].arrival_date,
                width: 150,
              },
              {
                field: "departure_date",
                headerName: translations[selected_locale].departure_date,
                width: 150,
              },
              {
                field: "rooms",
                headerName: translations[selected_locale].rooms,
                minWidth: 200,
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                  return (
                    <Box display={"flex"} flexWrap="wrap">
                      {params.value.map(({ room }) => (
                        <>
                          <Chip label={room.room_number} sx={{ mr: 1 }} />
                        </>
                      ))}
                    </Box>
                  );
                },
              },
              {
                field: "pets",
                headerName: translations[selected_locale].pets,
                width: 75,
                valueFormatter: (params) => `${params.value || 0}`,
              },

              {
                field: "created_at",
                headerName: translations[selected_locale].created_at,
                width: 150,
                valueFormatter: (params) =>
                  `${dayjs(params.value).format("YYYY-MM-DD")}`,
              },
              {
                field: "updated_at",
                headerName: translations[selected_locale].updated_at,
                width: 150,

                valueFormatter: (params) =>
                  `${dayjs(params.value).format("YYYY-MM-DD")}`,
              },
              {
                field: "actions",
                sortable: false,
                filterable: false,
                headerName: "",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => (
                  <>
                    <Button
                      color="secondary"
                      variant="outlined"
                      to={`/reservations/${params.id}`}
                    >
                      {translations[selected_locale].update}
                    </Button>
                  </>
                ),
              },
            ]}
            disableSelectionOnClick
            localeText={translations[selected_locale].datagrid}
          />
        </div>
      </Box>
    </Container>
  );
};

export default IndexPage;
